import Vue from 'vue'
import App from '../modules/360.vue'
import { VueHammer } from 'vue2-hammer'
// import VueAxios from 'vue-axios'
import TurbolinksAdapter from 'vue-turbolinks'
// import { securedAxiosInstance, plainAxiosInstance } from 'api/axios'
Vue.use(VueHammer)
// Vue.use(VueAxios, {
// secured: securedAxiosInstance,
// plain: plainAxiosInstance
// })
Vue.use(TurbolinksAdapter)
document.addEventListener('turbolinks:load', () => {
    console.log('Vue started')
    let element = document.getElementById("viewer_360")
    if (element) {
        let productCode = element.dataset.id
        const app = new Vue({
            el: '#viewer_360',
            data: {
                productCode
            },
            components: { App },
            mounted() {
                console.log('mounted');
            },
            template: '<App :id="productCode"/>'
        })
    }

})
<template>
  <viewer-360
    :rows="3"
    :cols="12"
    :imagePath="imagePath"
    fileName="img_0_{row}_{col}.jpg?v1"
  >
  </viewer-360>
</template>
</viewer-360>
</template>

<script>
import Viewer360 from "components/Viewer360/Viewer360";
// import "vue-360/dist/css/style.css";

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  components: {
    Viewer360,
  },
  computed: {
    imagePath() {
      return `/360/${this.id}`;
    },
  },
};
</script>
